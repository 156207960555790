.footerContainer{
    background-color: #270949;
    
}

.footerInnerDiv{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footerFlexBox{
    display: flex;
    justify-content: center;
}

.footerName{
    color:#fff;
    font-weight:bold;
    padding-top:2.5px;
}

@media screen and (max-width:450px) {
    .footerContainer{
        background-color: #270949;
    }
    
    .footerInnerDiv{
        height: 16vh;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .footerFlexBox{
        display: flex;
        justify-content: center;
    }

    .footerName{
        color:#fff;
        font-weight:bold;
        padding-top:7px;
    }
    
}