#mainDivHeadingText{
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: 2px;
}

.contentHolder{
    color: #270949;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 70vh;
}

#estimateButton{
    background-color: #4654A3;
    width: 300px;
    height: 55px;
    margin-top: 50px;
    padding-top: 16px;
    padding-left: 14px;
    color: white;
    text-decoration: none;
}

#estimateButton:hover{
    background-color: #270949;
}

#homeImage{
    width: 675px;
    height: 675px;
    margin-left: 50px;
}

.partnersContent{
    height: 80vh;
}

.partnersContentHolder{
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 70vh;
}

.readMoreButton{
    background-color: #4654A3;
    width: 155px;
    height: 55px;
    padding-left: 14px;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
}

.readMoreButton:hover{
    background-color: #270949;
    color: white;
}

.partnerMainHeading{
    color: #270949;
    font-weight: bold;
    font-size: 2.5rem;
    padding-right: 100px;
}

.partnerSubHeading{
    color: #270949;
    padding-right: 100px;
    margin-top: 10px;
    margin-bottom: 50px;
}

.partnersInnerDivSecond{
    border-left: 1px solid #4654A3;
    padding-left: 50px;
}

.testimonialDiv{
    height: 50vh;
}

.testimonialHolder{
    height: 45vh;
    padding-left: 300px;
    padding-right: 300px;
}

.testimonialContentHolder{
    height: 45vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.testimonialContentHolder:nth-of-type(2){
    padding-left: 50px;
}

.testimonialPerson{
    border-radius: 15px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff;
}

.testimonialQuote{
    font-weight: bold;
    font-size: 2rem;
}

.testminoialMainText{
    font-size: 0.8rem;
    letter-spacing: 1px;
}

.testimonialName{
    font-weight: bold;
    margin-top: 30px;
}

.testimonialImage{
    width: 156px;
    height: 180px;
}

.codeCircle{
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4654A3;
    border-radius: 50%;
}

.servicesDiv{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
    padding-bottom: 80px;
}

.servicesCard{
    height: 40vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #ffffff;
    margin-bottom: 30px;
    text-align: center;
    padding-left: 80px;
    padding-right: 80px;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff; 
}

.serviceCardHeading{
    color: #270949;
    font-weight: bold;
    margin-top: 20px;
    font-size: 2rem;
}

.serviceCardText{
    color: #270949;
    margin-top: 20px;
}

.readMoreServiceButton{
    background-color: white;
    border: 1px solid #4654A3;
    color: #4654A3;
    text-decoration: none;
    padding: 10px 100px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.3s;
}

.readMoreServiceButton:hover{
    background-color: #270949;
    border: 1px solid #270949;
    color: white;
    text-decoration: none;
    padding: 10px 100px;
    text-align: center;
    margin-top: 30px;
}

.estimateProjectButton{
    background-color: white;
    border: 1px solid #4654A3;
    color: #4654A3;
    text-decoration: none;
    padding: 10px 30px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.3s;
    width: 300px;
}

.estimateProjectButton:hover{
    background-color: #270949;
    border: 1px solid #270949;
    color: white;
    text-decoration: none;
    padding: 10px 30px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.3s;
    width: 300px;
}

@media screen and (max-width:450px) {

    .contentHolder{
        padding-left: 20px;
        flex-flow: column;
        justify-content: flex-start;
        height: 40vh;
        color: #270949;
    }

    #estimateButton{
        margin-top: 40px;
        background-color: #4654A3;
    }

    #mainDivHeadingText{
        font-weight: bold;
        font-size: 1.7rem;
        letter-spacing: 2px;
        margin-top: 50px;
    }

    #homeImage{
        width: 350px;
        height: 300px;
        margin-left: 0px;
    }

    .partnersContentHolder{
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        height: 35vh;
    }


    .partnersContent{
        height: 110vh;
    }

    .partnerMainHeading{
        color: #270949;
        font-weight: bold;
        font-size: 1.7rem;
        padding-right: 10px;
        margin-top: 100px;
    }
    
    .partnerSubHeading{
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .partnersImageHolder{
        margin-top: 50px;
    }

    .partnersInnerDiv{
        border-left: 0px solid gray;
        padding-left: 10px;
    }

    .partnersInnerDivSecond{
        border-left: 0px solid gray;
        padding-left: 10px;
    }
    
    .testimonialHolder{
        height: 90vh;
        padding-left: 70px;
        padding-right: 70px;
        margin-top: 3vh;
    }
    
    .testimonialContentHolder{
        height: 40vh;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    
    .testimonialContentHolder:nth-of-type(2){
        padding-left: 0px;
    }
    
    .testimonialPerson{
        border-radius: 15px;
        background: #ffffff;
        box-shadow:  20px 20px 60px #d9d9d9,
                    -20px -20px 60px #ffffff;
        
    }
    
    .testimonialQuote{
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
    }
    
    .testminoialMainText{
        font-size: 0.8rem;
        letter-spacing: 1px;
        text-align: center;
    }
    
    .testimonialName{
        font-weight: bold;
        margin-top: 30px;
        text-align: center;
    }

    .testimonialCompany{
        text-align: center;
    }

    .testimonialImage{
        width: 226px;
        height: 180px;
    }

    .servicesDiv{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
        padding-bottom: 80px;
        margin-top: 60vh;
        height: auto;
    }

    .servicesCard{
        height: 55vh;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #ffffff;
        margin-bottom: 30px;
        text-align: center;
        padding-left: 80px;
        padding-right: 80px;
        box-shadow:  20px 20px 60px #d9d9d9,
                    -20px -20px 60px #ffffff; 
    }

    .estimateProjectButton{
        background-color: white;
        border: 1px solid black;
        color: black;
        text-decoration: none;
        padding: 10px 20px;
        text-align: center;
        margin-top: 30px;
        transition: all 0.3s;
        width: 250px;
        font-size: 0.9rem;
    }

    .footerMargin{
        margin-top: 50vh;
    }
}