@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../src/assets/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

.App {
  font-family: "Poppins"!important;
  background-color: white;
}