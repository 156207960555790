#ssDivMainHeading{
    color: #270949;
    font-size: 3.5rem;
    font-weight: bold;
    margin-top: 10vh;
}

#ssDivMainSubHeading{
    color: #270949;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10vh;
}

#ssDivMainText{
    color: #4654A3;
    font-size: 1.2rem;
    margin-top: 40px;
}

.ssCards{
    border-radius: 15px;
    background: #ffffff;
    margin-top: 2vh;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff;
    height: 45vh;
    padding: 30px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    transition: all 0.2s;
}

.ssCards:hover{
    border-bottom: 5px solid #270949;
}

.codeCircle2{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4654A3;
    border-radius: 50%;
}

.ssReadMoreButton{
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #270949;
    color:#270949;
    text-decoration: none;
    width: 160px;
    margin-top: 30px;
    transition: all 0.2s;
}

.ssReadMoreButton:hover{
    padding: 10px 20px;
    background-color: #270949;
    border: 1px solid #270949;
    color: white;
    text-decoration: none;
    width: 160px;
}

.ssCardHeading{
    color: #270949;
    margin-top: 30px;
    font-weight: bold;
}

.ssCardText{
    color: #4654A3;
    margin-top: 20px;
}

#techHeading{
    color: #270949;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 10vh;
}

#techText{
    color: #4654A3;
    font-size: 1.1rem;
    margin-top: 20px;
}

.techType{
    color: #270949;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 7vh;
}

#businessHeading{
    color: #270949;
    margin-top: 10vh;
    font-weight: bold;
    font-size: 2.2rem;
}

#businessText{
    color: #4654A3;
    margin-top: 3vh;
    font-size: 1.1rem;
}

.specialistDiv{
    background-color: #270949;
    height: 60vh;
    margin-top: 10vh;
}

.specCol{
    height: 60vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

#TeamImage{
    width: 700px;
    height: 400px;
}

.specialListHeading{
    color:white;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.spUl{
    color:white;
}

.spLi{
    font-size:1.2rem;
    margin-bottom: 10px;
}

.ourDevelopment{
    background-color: rgb(241, 241, 241);
    height: auto;
    padding-bottom: 8vh;
}

#ourDevelopmentHeading{
    color: #270949;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 1px;
    padding-top: 8vh;
}

#ourDevelopmentText{
    color: #4654A3;
    padding-right: 500px;
    padding-top: 20px;
}

.numberHolder{
    width: 50px;
    height: 50px;
    background-color: #4654A3;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    border-radius: 100%;
    font-weight: bold;
    color: white;
    margin-top: 3vh;
}

.ourDevelopmentCardHeading{
    color: #4654A3;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 3vh;
}

.ourDevelopmentCardText{
    color: #4654A3;
    font-size: 1rem;
    margin-bottom: 3vh;
    padding-right: 40px;
}

.readMoreOurDevelopment{
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #1C55FF;
    color:#1C55FF;
    text-decoration: none;
    width: 160px;
    margin-top: 30px;
    transition: all 0.2s;
}

.readMoreOurDevelopment:hover{
    padding: 10px 20px;
    background-color: #1C55FF;
    border: 1px solid #1C55FF;
    color: white;
    text-decoration: none;
    width: 160px;
}

.questionDiv{
    height: 30vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.questionDivHeading{
    font-size: 2rem;
    font-weight: bold;
}

.questionDivButton{
    width: 200px;
    padding: 15px 5px;
    border:1px solid #4654A3;
    text-align: center;
    text-decoration: none;
    background-color: #4654A3;
    color: white;
    margin-top: 3vh;
    transition: all 0.2s;
}

.questionDivButton:hover{
    width: 200px;
    padding: 15px 5px;
    border:1px solid #270949;
    color: white;
    margin-top: 3vh;
    background-color: #270949;
}

@media screen and (max-width:450px) {
    #ssDivMainHeading{
        color: #270949;
        font-size: 2rem;
        font-weight: bold;
        margin-top: 5vh;
    }
    
    #ssDivMainSubHeading{
        color: #270949;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 7vh;
    }
    
    #ssDivMainText{
        color: #4654A3;
        font-size: 1rem;
        margin-top: 20px;
    }

    .ssCards{
        height: 55vh;
    }

    #businessHeading{
        margin-top: 10vh;
        font-weight: bold;
        font-size: 1.5rem;
    }
    
    #businessText{
        margin-top: 3vh;
        font-size: 1rem;
    }

    .specCol{
        height: 50vh;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    .specialistDiv{
        background-color: rgb(26, 26, 26);
        height: 103vh;
        margin-top: 10vh;
    }

    #TeamImage{
        width: 365px;
        height: 300px;
    }
    
    .specialListHeading{
        color:white;
        font-weight: bold;
        font-size: 2rem;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }

    #ourDevelopmentHeading{
        font-weight: bold;
        font-size: 1.6rem;
        letter-spacing: 1px;
        padding-top: 8vh;
    }
    
    #ourDevelopmentText{
        padding-right: 10px;
        padding-top: 20px;
    }

    .questionDivHeading{
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }
    
}