.contactUsInnerDiv{
    height: 90vh;
}

.optionsHolder{
    height: auto;
    padding: 20px;
    border-radius: 15px;
    background: #ffffff;
    margin-top: 2vh;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff;
}

.optionsLabel{
    padding-left: 20px;
    font-size: 1.1rem;
}

#addressHolder{
    padding-right: 300px;
}

@media screen and (max-width:450px) {

    .contactUsInnerDiv{
        height: 160vh;
    }

    .optionsLabel{
        padding-left: 5px;
    }

    #addressHolder{
        padding-right: 10px;
    }
}