#aboutUsMainHeading{
    color:#270949;
    font-weight: bold;
    font-size: 3.5rem;
    margin-top: 10vh;
}

#aboutUsMainSubHeading{
    color:#270949;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 10vh;
}

.questionDivHeading{
    color:#270949;
}

.standOutDiv{
    background-color: #BDBBF1; 
    margin-top: 10vh;
    height: auto;
    padding: 50px;
}

.teamDiv{
    margin-top: 10vh;
    /*padding-right: 260px;*/
}

.customersDiv{
    padding-top: 40px;
    
}

@media screen and (max-width:400px) {
    #aboutUsMainHeading{
        color:#270949;
        font-weight: bold;
        font-size: 2.5rem;
        margin-top: 5vh;
    }
    
    #aboutUsMainSubHeading{
        color:#270949;
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 5vh;
    }

    .questionDivHeading{
        color:#270949;
    }

    .teamDiv{
        margin-top: 10vh;
        padding-right: 20px;
    }
}