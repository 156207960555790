.navbar{
    background-color: white!important;
}

.nav-link{
    color: #270949!important;
    font-size: 1rem;
    letter-spacing: 2px;
    margin-left: 25px;
}

.nav-link:hover{
    color: #270949!important;
    font-weight: bold;
}

@media screen and (max-width:400px) {
    .nav-link{
        margin-left: 7px;
        margin-top: 10px;
    }    
}
